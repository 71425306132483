:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --primary-bg-color: #282c34;
  --secondary-bg-color: #575969;
}

.App {
  font-family: var(--text-font);
  text-align: center;
  background-color: var(--primary-bg-color);
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

header {
  display: none;
}

header h1 {
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}

.menu button {
  background: none;
  border: none;
}


#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
}

pre {
  font-family: var(--text-font);
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navCloseIcon {
  width: 20%;
  margin: 10px;
}

nav svg {
  float: left;
}

.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: #202022;
}



.chatBox {
  flex: 1;
  background-image: url("./assets/main_website_image.jpeg");
  background-size: cover;
  position: relative;
  line-height: 24px;
  color: rgb(255, 255, 255);
  font-size: 16px;
}


#introsection {
  text-align: justify;
  margin: 5% 10%;
  padding: 2% 5%;
  /* background-color: white; */
  color: black
}

#introsection h1 {
  line-height: 33px;
  text-align: center;
  font-size: 30px;
}
#introsection h2 {
  font-size: 20px;
  font-weight: 500;
}

#introsection ul {
  list-style-type: square;
}

#introsection pre {
  margin: 0;
  display: inline;
}

.chatLogWrapper {
  height: 80vh;
  overflow-y: auto;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}

::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
  column-gap: 25px;
}

#chatPrompt {
  color: black;
}

.userSVG {
  transform: scale(0.6);
}

.botMessageMainContainer {
  width: 100%;
  background-color: #41414e;
  position: relative;
  color: white
}

.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}



.customAiLogo {
  transform: scale(0.5);
}

#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  margin-top: 40px;
  left: 50%;
  right: 50%;
  bottom: 0;
  position: relative;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 200px;
  height: 55px;
  background-color: #1570ef;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
}

.inputPrompttTextarea {
  padding: 10px;
  padding-right: 50px;
  flex: 1;
  resize: none;
  background-color: #41414e;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: hidden;
}

form button {
  background: rgb(65, 65, 78);
  border: none;
  position: absolute;
  top: 10%;
  right: 0px;
  width: 35px;
  height: 45px;
}

@media (hover: hover) {
  .promptButton:hover {
    cursor: pointer;
    background: #212023;
    border-radius: 20%;
  }
}

@media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }

  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
  }

  nav {
    display: flex;
  }

  .sideMenu {
    display: none;
  }

  .chatBox {
    position: static;
  }

  .chatPromptWrapper {
    padding: 12px;
  }

  .botMessageWrapper {
    padding: 12px;
  }

  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }

  .userSVG {
    transform: scale(0.5);
  }

  .customAiLogo {
    transform: scale(0.5);
  }

  #avatar {
    width: 30px;
    height: 30px;
  }

  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }

  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }

  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }

  .loginContainer p {
    font-size: 25px;
  }

  #loginButton {
    font-size: 22px;
  }
}


.modal {
  font-size: 20px;
  text-align: center;
}

.fileUpload {
  font-size: 15px;
}

.modal>.instruction {
  padding-top: 5%;
}

.fileUploadHelperText {
  font-size: 20px;
  padding-top: 5%;
}

.UrlUploadHelperText{
  width: 50%;
}

.websiteUpload {
  padding-top: 1%;
}

.fileUpload>p {
  font-size: 20px;
  padding-top: 5%;
}

.fileUpload>div {
  font-size: 20px;
  padding-top: 5%;
}

.modal {
  height: 100%;
}

.modal>.content {
  height: 100%;
}


.fileUploadButton {
  font-size: 18px;
  background-color: #1570ef;
  color: white;
  border-color: #1570ef;
}


.modal {
  color: black;
  background-image: url("./assets/main_website_image.jpeg");
  background-size: cover;
  font-weight: 700;
}

.modal>.header {
  width: 100%;
  border-bottom: 2px solid white;
  font-size: 25px;
  text-align: center;
  padding: 2px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #1570ef;
  color: white;
}

.modal> .disclaimer {
  font-size: 12px;
  font-style: italic;
  font-weight: lighter;
}

.waitingAnimationGif {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.waitingAnimationMessage {
  padding-bottom: 5%;
}